import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import CustomComponent from "../components/elements/custom-component"

// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { usePageHierarchy } from "../hooks/use-page-hierarchy"
import { useGroupedComponents } from "../hooks/use-grouped-components"

const PageGeneralTemplate = ({ data }) => {
  const asmetHome = useAsmetHome()

  const pageGeneral = data.pageGeneral
  const pageHierarchy = usePageHierarchy(pageGeneral.strapiId)
  const groupedComponents = useGroupedComponents(pageGeneral.contenido_general)

  const [doHeader, setDoHeader] = useState(true)
  useEffect(() => {
    setDoHeader(pageGeneral.contenido_general[0].tipo_componente !== "Menu")
  }, [pageGeneral.contenido_general])

  const crumbs = []

  pageHierarchy.forEach(el => {
    crumbs.push({ path: `/pagina/${el.slug}`, label: el.titulo })
  })

  const [loaded, setLoaded] = useState(false)

  return (
    <Layout
      meta={
        pageGeneral.meta ? pageGeneral.meta : { og_titulo: pageGeneral.titulo }
      }
    >
      {doHeader && (
        <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
          <div className="container">
            <div className="row">
              <div className="col">
                <Breadcrumb crumbs={crumbs} />
                <h3 className="text-white-alpha text-left pt-4">{pageGeneral.titulo}</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="asm-page-general"
        onLoad={() => setLoaded(true)}
        {...(loaded ? { className: "asm-element-loaded" } : {})}
      >
        {groupedComponents.map((item, index) => {
          return <CustomComponent component={item} index={index} key={index} pageId={pageGeneral.slug} />
        })}
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default PageGeneralTemplate

export const query = graphql`
  query PageGeneral($id: String!) {
    pageGeneral: strapiAsmetPagina(strapiId: { eq: $id }) {
      strapiId
      titulo
      slug
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }

      contenido_general {
        id
        tipo_componente

        # Component Menu
        titulo
        subtitulo
        links {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Texto

        contenido

        # Component Documento

        fecha_publicacion
        es_descargable
        descripcion
        archivo {
          publicURL
          extension
        }

        # Component Foto

        media_fotos {
          id
          slug
          titulo
          fecha
          imagenes {
            formats {
              thumbnail {
                publicURL
              }
              small {
                publicURL
              }
              medium {
                publicURL
              }
              large {
                publicURL
              }
            }
            url
          }
          descripcion
        }
        
        # Component Video

        media_videos {
          id
          slug
          titulo
          link
          descripcion
          publicado_en
          tipo
        }

        # Componente Audio

        media_audios {
          id
          slug
          titulo
          archivo {
            publicURL
          }
          descripcion
          publicado_en
          tipo
        }

        # Component Banner

        slides {
          tipo_componente
          titulo
          subtitulo
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Card

        imagen {
          publicURL
        }
        link {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Grupo Cards

        cards {
          tipo_componente
          titulo
          descripcion
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Lista Desplegable Texto
        # Component Lista Desplegable Documento

        documentacion {
          tipo_componente
          titulo
          fecha_publicacion
          es_descargable
          descripcion
          archivo {
            publicURL
            extension
          }
        }

        # Component Pestanas Listas Desplegables

        icono {
          publicURL
        }
        listas_documentacion {
          tipo_componente
          titulo
          documentacion {
            tipo_componente
            titulo
            fecha_publicacion
            es_descargable
            descripcion
            archivo {
              publicURL
              extension
            }
          }
        }
        listas_texto {
          tipo_componente
          titulo
          contenido
        }
      }
    }
  }
`
