import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "../elements/custom-link"

const Menu = props => {
  const { menuSecundario } = props
  return (
    <div id="asm-menu" className="container my-0 my-sm-5">
      <div className="row">
        {menuSecundario.map((item, index) => {
          return (
            <div className="col-sm text-center" key={index}>
              <CustomLink
                link={item}
                customClass={"nav-link" + (index === 0 ? " active" : "")}
              >
                <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
                {item.nombre}
              </CustomLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Menu.propTypes = {
  menuSecundario: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

Menu.defaultProps = {
  menuSecundario: [
    {
      nombre: `Afiliación a la Mano`,
      tipo: `Pagina`,
      link_id: `/`,
      icono: {
        publicURL: `/static/6e42e9fd87b792be6a3040d64eeec6b3/36e2e83e7518ebc69a1b6b2108c16be8.png`,
      },
    },
  ],
}

export default Menu
